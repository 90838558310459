const { pdiLogo } = require('./pdi-logo')

const { html, qc } = common.cmp

const goToChangePassword = canSkip => {
  const styles = () =>
    html(`<style>
  .login {
    position: absolute;
    width: 30em;
    top: calc(50% - 8.87em);
    left: calc(50% - 15em);
    margin-top: 0 !important;
    background: #739ea8;
    box-shadow: 0.077em 0.231em 0.39em rgba(0, 0, 0, 0.5);
    border-radius: 0.231em;
    z-index: 1;
    animation: fadeIn 1.5s;
  }
  .k-widget.k-window.k-window-titleless div.login {
    position: absolute;
    width: 30em;
    left: calc(50% - 15em);
    margin-top: 0 !important;
    background: #739ea8;
    box-shadow: 0.077em 0.231em 0.39em rgba(0, 0, 0, 0.5);
    border-radius: 0.231em;
    z-index: 1;
    animation: fadeIn 1.5s;
  }
  .login .ow-ctl-wrap,
  .login input.k-textbox[type='text'],
  .login input.k-textbox[type='password'],
  .login input[type='submit'] {
    display: block;
    width: 27em;
    margin: 2em auto;
    opacity: 1;
    border: 0.077em solid #ceced2;
    border-radius: 0.231em;
  }
  .login input.k-input[type='text'] {
    margin: 1rem auto;
  }\
  .changePasswordButton {
    width: 100%;
    align-items: center;
    text-align: center;
    margin: 1em auto;  
  }
  .changePasswordButton input {
    color: #fff;
    background-color: #307ebe;
    border-color: #2e6a9c;
    box-shadow: 0.077em 0.154em 0.231em rgb(0 0 0 / 25%);
    padding-top: 0.285em;
    padding-bottom: 0.285em;  
    margin-left: 0.077em;  
  }  
  .changePasswordButton input:active, 
  .changePasswordButton input:hover {
    color: #fff;
    background-color: #1984c8;
    border-color: #1984c8;
  }  
   </style>`)

  const oldP = qc('input#oldpassword.k-textbox').attr({
    type: 'password',
    placeholder: __('OldPassword')
  })
  const newP = qc('input#newpassword.k-textbox').attr({
    type: 'password',
    placeholder: __('NewPassword')
  })
  const confirmP = qc('input#confirmpassword.k-textbox').attr({
    type: 'password',
    placeholder: __('ConfirmPassword')
  })
  const btn = qc('input#changepwd-button.k-button').attr({
    type: 'button',
    'data-default-button': 'true',
    value: __('ChangePassword')
  })
  const btnCancel = qc('input#cancel-changepwd-button.k-button')
    .attr({ type: 'button', value: __('Cancel') })
    .on('click', e => {
      e.preventDefault()
      screenLock.el.remove()
    })

  const btnCont = qc('div.changePasswordButton', canSkip ? [btn, btnCancel] : [btn])

  const formContent = [
    qc('div.logo', pdiLogo()).css({ padding: '1em' }),
    [html(styles()), oldP, newP, confirmP, btnCont]
  ]
  const form = qc('form#changePwdForm.login.k-window', formContent).css({
    background: '#f3f6f8',
    boxShadow: '0.077em 0.154em 0.231em rgba(0, 0, 0, 0.25)',
    border: '0 none !important',
    borderRadius: '0.231em'
  })
  const screenLock = qc('div#screenlock', form).css({ opacity: '1' })
  screenLock.renderTo(document.body)

  oldP.el.focus()

  const validate = d => {
    if (d.ConfirmPassword.trim() !== d.Password.trim()) {
      ow.popError(__('ChangePwdErrorTitle'), __('PasswordAndConfirmPwdMismatch'), 5000)
      return false
    }
    return true
  }

  btn.on('click', e => {
    const setBusy = v =>
      v ? qc(document.body).addClass('wait') : qc(document.body).removeClass('wait')

    e.preventDefault()
    let data = {
      OldPassword: oldP.el.value,
      Password: newP.el.value,
      ConfirmPassword: confirmP.el.value
    }
    if (!validate(data)) return
    setBusy(true)
    $ajax({
      type: 'PUT',
      url: '/update-password',
      data
    })
      .then(response => {
        setBusy(false)
        if (response.ResCode !== 0) {
          oldP.el.focus()
          return ow.popError(__('ChangePwdErrorTitle'), response.errMsg || '', 5000)
        }
        if (canSkip) {
          screenLock.el.remove()
          return ow.popSuccess(__('PasswordChanged'))
        }
        sessionStorage.removeItem('changepassword')
        window.location.reload()
      })
      .catch(err => ow.popError(__('ChangePwdErrorTitle'), err.errMsg))
      .finally(() => setBusy(false))
  })
}

module.exports = { goToChangePassword }
