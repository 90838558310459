const { __, $ajax, io } = window
const { qc } = common.cmp

const setDownloaded = TaskID =>
  $ajax({
    url: 'data/readyreport/method/downloaded',
    method: 'POST',
    data: { TaskID }
  }).then(res => {
    if (res.error)
      ow0.popError(
        __('ERROR'),
        typeof res.error === 'string' ? res.error : JSON.stringify(res.error),
        5000
      )
  })

/**
 * finds div.download-notify and adds a button that opens readyreport-overview in click
 * @returns
 */
const downloadReportButton = () =>
  qc('button.k-button', __('Downlaod'))
    .attr({ type: 'button' })
    .on('click', () =>
      ow0.windows.openView({
        name: 'Report Download',
        url: '/views/readyreport-overview.js',
        useExisting: true
      })
    )

const downloadTargetReport = d =>
  qc('button.green-button.k-button', __('Open'))
    .attr({ type: 'button' })
    .on('click', () =>
      $ajax({
        url: 'data/readyreport/method/download',
        method: 'POST',
        data: d
      }).then(res => {
        if (res.error) {
          var errMsg = typeof res.error === 'string' ? res.error : JSON.stringify(res.error)
          ow0.popError(__('ERROR'), errMsg, 5000)
        }

        if ((res.ExportType === 0 || res.ExportType === 1) && res.ExportDataList)
          res.ExportDataList?.forEach(x =>
            ow0.windows.openPdf(x.exportFileName, x.exportFileName, x.data)
          )

        setDownloaded(d.TaskID)
      })
    )

const notifyReady = r =>
  ow0.popInfo(
    __('System'),
    [
      ow0.htmlEncode(r.TaskName) + ' ' + __('ReportReadyDownload'),
      qc('div.download-notify', [downloadTargetReport(r), downloadReportButton()]).css({
        textAlign: 'right'
      })
    ],
    0 //dont close
  )

const processPoll = (r = {}) => {
  if (r.TaskStatus === 10) ow0.popError('Error', r.TaskMessage, 5000)
  else if (r.TaskStatus === 9) notifyReady(r)

  //ack alert
  return $ajax({
    method: 'POST',
    url: '/data/readyreport/method/notified',
    data: { TaskID: r.TaskID }
  }).catch(err => console.error(err))
}

exports.initReadyReport = () => io.socket.on('readyreport', processPoll)
