const { theme, pdiIconStyles, colors } = require('./pdi-theme')
const { html, qc } = common.cmp
const { input4 } = common.controls
const { $find, $hasClass, $addClass, $removeClass } = common.nojquery

const killEvent = e => common.killEvent(e, false)

const reportFuncId = 9002

let topMenuList = []

const isReport = m =>
  (m.AppFunc?.FunctionType === 3 && m.AppFunc.Command.trim()?.length) ||
  m.FunctionID === reportFuncId

const clickMenu = data => {
  if (data.menuChild?.length) return

  isReport(data)
    ? ow.openReport(...(data.Command.trim() || data.AppFunc.Command.trim()).split(';'))
    : ow.windows.showWindow(
        data.display,
        data.menuSetting?.viewFileName,
        data.menuSetting?.windowWidth,
        data.menuSetting?.windowHeight,
        data.FunctionID,
        ...(data.Command?.trim().length ? [data.Command] : [])
      )

  return false
}

const topSearch = () => {
  let open = false

  let results = []

  const searchDiv = param => {
    $find('.ulSearchMenu')[0]?.remove()
    if (!param) {
      searchCombo.val('')
      return
    }

    param = param.toLowerCase()

    results = topMenuList.filter(m => {
      if (m.FunctionID === 0) return false
      if (m.display.toLowerCase().indexOf(param) < 0) return false
      return true
    })

    return qc(
      'ul.ulSearchMenu.animate',
      results.map(m =>
        qc('li', [qc('a', m.display).attr({ href: '#' })]).on('click', () => {
          clickMenu(m)
          searchDiv()
        })
      )
    )
      .css({
        zIndex: '999999999',
        top: '3rem'
      })
      .renderTo(searchBox.el)
  }

  let me, searchCombo, searchBox, searchIcon

  return (me = qc('nav.topmenu-search', [
    (searchBox = qc(
      'span',
      (searchCombo = input4({
        ctlType: 'text',
        placeholder: __('Search'),
        list: topMenuList,
        filter: 'contains'
      })
        .css({
          borderRadius: '4px',
          lineHeight: '28px',
          height: '28px',
          paddingLeft: '4px',
          outline: 'none',
          width: '100%',
          border: 'none'
        })
        .on('input', () => searchDiv(searchCombo.val()))
        .on('blur', () => {
          setTimeout(() => {
            open = false
            me.renderAsync()
          }, 100)
        })
        .on('keydown', e => {
          if (e.which !== 13) return
          const data = results[0]
          if (!data) return
          clickMenu(data)
          searchCombo.val('')
          searchDiv() // clears list
          open = !open
          me.renderAsync()
        }))
      // .wrap().css({ whiteSpace: 'nowrap' })
    )
      .css({
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        display: 'inline-block',
        overflow: 'visible',
        height: 'inherit',
        transition: 'width 0.25s, visibility 0.25s'
      })
      .bindState(() =>
        searchBox.css({
          width: open ? '20em' : '0',
          visibility: open ? 'visible' : 'hidden'
        })
      )),
    (searchIcon = qc('span.fa')
      .bindState(() => {
        const searchIconClass = 'fa-search' // 'pdi-icon-Search'
        const timesIconClass = 'fa-times' // 'pdi-icon-Back-Arrow'

        open
          ? searchIcon.removeClass(searchIconClass).addClass(timesIconClass)
          : searchIcon.addClass(searchIconClass).removeClass(timesIconClass)
      })
      .on('click', function () {
        open = !open
        me.renderAsync().then(() =>
          setTimeout(() => {
            open ? searchCombo.el.focus() : searchCombo.val('')
            computeTopMenuCollapse()
          }, 250)
        )
      })
      .css({
        display: 'inline-block',
        cursor: 'pointer',
        fontSize: '1.3em',
        padding: '0px 5px'
      }))
  ]).css({ padding: '0px 5px' }))
}

const windowResizeEvent = handler => {
  window.addEventListener('resize', () => {
    ow.debounce(() => handler(), 500, false)()
  })
}

const computeTopMenuCollapseNextNav = nextLink => {
  const collapseMenuLi = $find('#collapse-menu > li')
  const collapseBtn = $find('#collapse-btn')
  if (
    (collapseMenuLi || []).some(menu =>
      [...menu.children].some(child => child.textContent === nextLink?.el.text)
    ) &&
    !$hasClass(collapseBtn, 'hide')
  ) {
    collapseBtn[0].click()
  }
}

const topCollapseBtn = () => {
  const a = qc('a.link-topmenu', [
    qc('i.fa.fa-ellipsis-v').css({ fontSize: '1.3rem' }),
    qc('ul#collapse-menu.animate')
  ])
    .css({ padding: '0 1em' })
    .attr({ href: '#' })
    .on('init', () => {
      setTimeout(() => {
        computeTopMenuCollapse()
        windowResizeEvent(computeTopMenuCollapse)
      }, 1000)
    })
    .on('keydown', function (e) {
      // 40=arrowdown
      if (e.keyCode === 40) {
        e.stopImmediatePropagation()
        tabSeq[1]?.el?.focus()
        return killEvent(e)
      }

      // 38=arrowup
      if (e.keyCode === 38) {
        e.stopImmediatePropagation()
        // prev()?.focus()
        return killEvent(e)
      }

      // 37=arrowleft
      if (e.keyCode === 37) {
        topLinks[Math.max(topLinkIndex - 1, 0)]?.el.focus()
        return killEvent(e)
      }

      // 39=arrowright
      if (e.keyCode === 39) {
        topLinks[Math.min(topLinkIndex + 1, topLinks.length - 1)]?.el.focus()
        return killEvent(e)
      }

      // escape
      // if (e.keyCode === 27) {}
    })

  const topLinkIndex = topLinks.push(a) - 1
  let tabSeq = [a]

  const li = qc('li#collapse-btn.hide', a)
    .css({
      padding: 0
    })
    .on('click', () => a.el.focus())

  const hasFocusWithin = () => document.activeElement?.closest('ul#nav')

  li.on('mouseleave', () => li.removeClass('menu-hover')).on(
    'mouseenter',
    () => !hasFocusWithin() && li.addClass('menu-hover')
  )

  return li
}

const computeTopMenuCollapse = () => {
  const navSpace = ($find('.menucategory ul') || [])[0]?.clientWidth
  const collapseBtn = $find('#collapse-btn')
  const initialLinkWidth = 70 // padding before it become double line
  const list = $find('.topmenu #nav > li')

  // calc the combined width of all links currently in the horizontal menu
  let linksWidth = list.reduce((acc, el) => (acc += el.clientWidth), initialLinkWidth)

  // compare them to see if all the links fit in the container
  if (linksWidth > navSpace) {
    // the width of the links is greater than the width of their container

    // keep moving links from the menu to the overflow until the combined width is less than the container
    while (linksWidth > navSpace) {
      const lastLink = ($find('.topmenu #nav > li:nth-last-child(2)') || [])[0] // get the last link
      const lastLinkWidth = lastLink?.clientWidth // get its width
      const collapseMenu = $find('#collapse-menu')[0]
      if (lastLink) {
        lastLink.dataset.nav = lastLinkWidth // store the width (see if it fits back in the space available later)
        collapseMenu.insertBefore(lastLink, collapseMenu.children[0]) // pop the link and push it to the overflow
      }
      linksWidth = linksWidth - lastLinkWidth // recalc the linksWidth since we removed one
    }

    $removeClass(collapseBtn, 'hide')
  } else {
    // the width of the links is less than the width of their container
    // move links from the overflow back into the menu until run out of room again

    while (linksWidth <= navSpace) {
      const overflowLink = $find('#collapse-menu > li') || []
      const firstOverflowLink = overflowLink[0]
      const firstOverflowLinkWidth = parseInt(firstOverflowLink?.dataset?.nav)
      const topmenuNav = $find('.topmenu #nav')[0]
      const isShowCollapseBtn = !$hasClass(collapseBtn, 'hide')

      if (navSpace - linksWidth > firstOverflowLinkWidth) {
        if (isShowCollapseBtn) {
          topmenuNav.insertBefore(
            firstOverflowLink,
            topmenuNav.children[topmenuNav.children.length - 1]
          )
        } else {
          topmenuNav.appendChild(firstOverflowLink)
        }
      }
      linksWidth = linksWidth + firstOverflowLinkWidth // recalc the linksWidth since we added one
    }

    // hide the overflow button
    if (($find('#collapse-menu > li') || []).length === 0) {
      $addClass(collapseBtn, 'hide')
    }
  }
}

const getTranslatedMenuName = p => {
  const txKey = 'MENU:' + p.Name
  const tx = __(txKey, true)
  return tx !== txKey ? tx : __(p.Name, true)
}

const createTopCardMenu = (tabSeq, menu) => {
  const cardColumn = (data, colIndex) => {
    const liColumn = qc('li.topmenu-column').props({ links: [] })

    // column header with icon
    const hdr = qc('a', [
      qc('span.list-icon')
        .css({
          marginLeft: '0rem',
          marginRight: '0.5rem',
          display: 'inline-block'
        })
        .addClass(data.WebMenuIcon),
      // .addClass('pdi-icon')
      // .removeClass('fa')
      qc('span.cat-header', getTranslatedMenuName(data))
    ]).attr({ tabIndex: '-1' })

    const menus = (data.children || []).map((m, rowIndex) => {
      m.display = getTranslatedMenuName(m)

      topMenuList.push(m)
      const link = qc('a.link-topmenu')

      liColumn.links.push(link)

      // for up down arrows
      const i = tabSeq.push(link) - 1
      const prev = () => tabSeq[i - 1]?.el
      const next = () => tabSeq[i === tabSeq.length - 1 ? 0 : i + 1]?.el

      return link
        .kids(m.display)
        .attr({ href: '#' })
        .on('keydown', e => {
          // arrowdown
          if (e.keyCode === 40) {
            next()?.focus()
            return killEvent(e)
          }

          // arrowup
          if (e.keyCode === 38) {
            prev()?.focus()
            return killEvent(e)
          }

          // arrowleft
          if (e.keyCode === 37) {
            const col = columns[Math.max(0, colIndex - 1)]
            col.links[rowIndex > col.links.length - 1 ? col.links.length - 1 : rowIndex]?.el.focus()
            return killEvent(e)
          }

          // arrowRight
          if (e.keyCode === 39) {
            const col = columns[Math.min(columns.length - 1, colIndex + 1)]
            col.links[rowIndex > col.links.length - 1 ? col.links.length - 1 : rowIndex]?.el.focus()
            return killEvent(e)
          }
          // escape
          // if (e.keyCode === 27) showHoverMenu()
          // e.stopImmediatePropagation()
          // e.stopPropagation()

          // return false
        })
        .on('click', () => clickMenu(m))
    })

    return liColumn.kids([
      hdr,
      qc(
        'ul',
        menus.map(mi => qc('li', mi))
      )
    ])
  }

  const columns = menu.children?.map(cardColumn) ?? []
  columns.forEach(col => (col.columns = columns))

  return qc('ul.menu-max-cols', columns)
    .css({
      padding: '1rem',
      display: 'grid',
      gap: '1rem',
      gridTemplateColumns:
        menu.children?.length > 2
          ? 'auto auto auto'
          : menu.children?.length === 2
          ? 'auto auto'
          : 'auto'
    })
    .css(theme.light)
}

const topLinks = []
const createTopFloatMenu = menus => {
  return qc('ul#nav', [
    menus.map(m => {
      let a = qc('a.link-topmenu', getTranslatedMenuName(m))
        .css({ display: 'inline-block' })
        .attr({ href: '#' })
        .on('keydown', function (e) {
          // 40=arrowdown
          if (e.keyCode === 40) {
            e.stopImmediatePropagation()
            tabSeq[1]?.el?.focus()
            return killEvent(e)
          }

          // 38=arrowup
          if (e.keyCode === 38) {
            e.stopImmediatePropagation()
            // prev()?.focus()
            return killEvent(e)
          }

          // 37=arrowleft
          if (e.keyCode === 37) {
            topLinks[Math.max(topLinkIndex - 1, 0)]?.el.focus()
            return killEvent(e)
          }

          // 39=arrowright
          if (e.keyCode === 39) {
            const nextLink = topLinks[Math.min(topLinkIndex + 1, topLinks.length - 1)]
            computeTopMenuCollapseNextNav(nextLink)
            nextLink?.el.focus()
            return killEvent(e)
          }

          // escape
          // if (e.keyCode === 27) {}
        })

      const topLinkIndex = topLinks.push(a) - 1
      let tabSeq = [a]
      let floatCard = createTopCardMenu(tabSeq, m)

      const li = qc('li', [
        qc('span', [
          a,
          qc('span.fa')
            // .addClass('pdi-icon-Chevron-Down')
            .addClass('fa-chevron-down')
            .css({
              fontSize: '9px',
              paddingLeft: '0.75rem',
              textDecoration: 'none'
            })
            .on('click', () => a.el.focus())
        ]).css({ whiteSpace: 'nowrap' }),
        floatCard
      ])

      const hasFocusWithin = () => document.activeElement?.closest('ul#nav')

      li.on('mouseleave', () => li.removeClass('menu-hover')).on(
        'mouseenter',
        () => !hasFocusWithin() && li.addClass('menu-hover')
      )

      return li
    }),
    topCollapseBtn()
  ])
}

const buildTopMenus = (topM, secondMenuItems) => {
  styles().renderTo(document.head)
  topM && createTopFloatMenu(secondMenuItems).renderTo(topM)
  ow.menu.openMenu = () => topLinks[0]?.el.focus()
  if (topM) setTimeout(() => ow.menu.openMenu(), 100)
}

const styles = () =>
  html(`<style>

${pdiIconStyles}

#nav .fa, 
#nav .fa:before { font-family: FontAwesome }
#nav .pdi-icon,
#nav .pdi-icon:before { font-family: pdi-icon }

#nav .menu-max-cols { border-radius: 4px }

#nav,
#nav ul { list-style-type: none; outline: none; }

#nav li { outline: none; }

.menucategory,
.topmenu-search {
  width: auto;
  background: inherit;
  line-height: inherit;
  height: inherit;
}

.topmenu-search ul { list-style-type: none; outline: none; }
.topmenu-search li { 
  line-height: 2rem;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden
}

#nav {
  padding: 0 0.62em;
  background: transparent;
  height: inherit;
  text-align: left;
}
#nav li.topmenu-column {
  width: 13rem;
  background: inherit;
  text-align: left;
  font-size: 1em;
  font-weight: normal;
  vertical-align: top;
}
#nav > li,
#nav .menu-max-cols > li {
  display: inline-block;
}
#nav ul ul li {
  margin: 0em 0em;
}
#nav > li {
  background: transparent;
  width: auto;
  padding: 0px 0.6em;
  margin: 0;
  position: relative;
  height: inherit;
}

#nav > li:focus-within > ul,
#nav > li.menu-hover > ul {
  top: auto;
  width: auto;
  left: 0px;
  transition: transform 0.25s;
  transform: translateY(0%);
}

#nav li.menu-hover { min-height: 0; }

#nav a:hover,
#nav a:focus { text-decoration: underline; }

.pdi #nav a:focus { color: ${colors.Sky}; }

#nav a {
  display: block;
  text-decoration: none;
  line-height: 2rem;
}

#nav > li > a {
  line-height: 2.5rem;
}

#nav > li > ul > li > a:first-child {
  border-bottom: 1px solid #dbdbdb;
  cursor: default;
}

#nav a span.cat-header {
  display: inline-block;
  font-weight: bold;
}
#nav > li > a,
#nav > li > ul > li > ul > li a,
#nav .link-submenu > a {
  font-size: inherit;
}
#nav > li > a {
  color: inherit;
}

/* --------- Drop Down -------- */
#nav > ul {
  width: auto;
}
#nav > li > ul {
  position: absolute;
  left: -999em;
  width: 12rem;
  background: inherit;
  box-shadow: 0 0.309em 0.618em 0 rgba(0, 0, 0, 0.2), 0 0.4629em 12rem 0 rgba(0, 0, 0, 0.19);
  transform: translateY(-1em);
}
#nav,
#nav .menu-max-cols {
  list-style: none;
}
#nav .menu-max-cols {
  overflow-y: auto;
  max-height: calc(100vh - 4em);
}
#nav ul li ul {
  position: relative;
  margin-top: 0.1716em;
  box-shadow: none;
}

#nav a > span.fa {
  display: inline-block;
}

#collapse-menu {
  list-style-type: none;
  max-height: calc(100vh - 4em);
  overflow-y: auto;
  color: #323E48;
  position: absolute;
}
#collapse-menu .fa-chevron-down {
  display: none;
}
#collapse-menu .topmenu-column {
  list-style-type: none;
}
#collapse-menu > li {
  padding-bottom: 1em;
}
#collapse-menu > li > ul {
  display: inline-block !important;
  padding: 0 !important;
  overflow-y: visible !important;
  background-color: transparent !important;
  max-height: 100% !important;
}
#collapse-menu .topmenu-column:only-child > a {
  display: none;
}
#collapse-menu .topmenu-column:not(:only-child) .cat-header {
  font-weight: bold;
}
#collapse-menu .topmenu-column:not(:only-child) > ul {
  padding: 0 1em 1em 1em;
}
#collapse-menu .topmenu-column:not(:only-child) .list-icon {
  display: none !important;
}
#collapse-menu .topmenu-column > ul > li {
  list-style-type: none;
}
#collapse-menu .topmenu-column > ul > li > a:hover {
  text-decoration: underline;
}
#collapse-menu > li > span > a {
  font-weight: bold;
}
#collapse-menu > li > span > a:hover {
  text-decoration: none;
}
#collapse-menu li:hover a {
  text-decoration: none;
}
#collapse-menu li > span {
  display: grid;
  border-bottom: 1px solid #dbdbdb;
  gap: 1rem;
}
.topmenu .hide {
  display: none !important;
}
</style>`)

module.exports = { buildTopMenus, topSearch, topCollapseBtn }
