// build the menu with templates
const { $ajax, nojquery } = common
const { ow0 } = window
const { qc } = common.cmp
const { $find } = nojquery

ow0.menu = ow0.menu || {}
const { sideMenu, getTranslatedMenuName } = require('./side-menu')
const { buildTopMenus } = require('./top-menu')

const rebuildShortcuts = () => {
  const p = $('.sub-menu2')[0]

  let m = ow0.menu.shortcuts ?? []

  m.sort((a, b) => (b.Name.toLowerCase() > a.Name.toLowerCase() ? -1 : 1))

  qc(p).kids([
    qc($('.subhead.favourites')[0]),
    ...m.map(m =>
      qc('li.k-item.k-state-default')
        .attr({ role: 'menuitem' })
        .kids([qc('a.k-link.subfav', getTranslatedMenuName(m.menuItem)).attr({ href: '#' })])
        .on('click', () => ow0.menu.clickMenu(m.menuItem))
    ),
    qc($('.subhead.open-windows')[0]),
    ...ow0.windows.findOpenWindows().map(w =>
      qc('li.animate.open-window', qc('a.subope', w.viewdata.name))
        .on('click', () => {
          w.setVisible(true)
          w.toFront()
        })
        .on('mouseover', function () {
          qc(document.body).addClass('highlight-window')
          qc(w[0].closest('.k-window')).addClass('highlight-window')
        })
        .on('mouseout', function () {
          qc(document.body).removeClass('highlight-window')
          qc(w[0].closest('.k-window')).removeClass('highlight-window')
        })
    )
  ])
}
window.rebuildShortcuts = rebuildShortcuts

const filterNoChildrenSideMenu = menuList => {
  const menuPanelId = [0, 10001, 10002]
  const search = data => {
    if (!menuPanelId.includes(data.FunctionID)) return true
    return data.menuChild.length
  }
  return menuList.filter(item => {
    let children = item.menuChild
    if (children && children.length) {
      item.menuChild = filterNoChildrenSideMenu(children, search)
      if (item.menuChild && item.menuChild.length) {
        return true
      }
    }
    return search(item)
  })
}

const buildMenu = async () =>
  $ajax({ url: '/menujson' }).then(response => {
    let {
      rawData,
      rawShortCuts,
      // iconMap,
      SelectedMenuGroup,
      secondMenuItems,
      secondMenuMaxColumn,
      isDBArchive
    } = response

    ow0.menu.secondMenuMaxColumn = secondMenuMaxColumn

    // build a map
    ow0.menu.menuItems = rawData

    rawShortCuts.forEach(fav => {
      rawData
        .filter(item => item.FunctionID === fav.FunctionID && item.Name === fav.Name)
        .forEach(mi => {
          fav.menuItem = mi
          mi.isFavourite = true
        })
    })

    rawShortCuts = rawShortCuts.filter(fav => fav.menuItem)
    ow0.menu.shortcuts = rawShortCuts

    // Note that the top level ordering is incorrect but the children are correctly ordered.
    let top
    rawData.forEach(mi => {
      mi.menuChild = []
      mi.FunctionID === 0 ? (top = mi) : (mi.top = top)
    })

    let tops = rawData.filter(x => x.FunctionID === 0)
    tops.reverse()
    tops.forEach(top => {
      const list = rawData.filter(kid => kid.top === top)

      // console.log(top.Name)
      let depth = 1
      let item
      const assignParents = parent => {
        item = list.shift(0) // removes and returns list[0]
        if (!item) return
        if (item.FunctionID === 10002) return depth--
        // console.log(
        //   ['', ' ', '  ', '   ', '    ', '     '][depth],
        //   item.LineNumber,
        //   item.Name,
        //   item.FunctionID
        // )
        item.parent = parent.LineNumber
        parent.menuChild.push(item)
        if (item.FunctionID === 10001) {
          depth++
          assignParents(item)
        }
        assignParents(parent)
      }
      assignParents(top)
    })

    tops = isDBArchive ? filterNoChildrenSideMenu(tops) : tops

    rebuildShortcuts()
    if (SelectedMenuGroup === 1) buildTopMenus($find('.menucategory')[0], secondMenuItems)
    else sideMenu(tops).renderTo(document.body)

    // override F3 to open Menu and search
    qc(document.body).on('keydown', e => {
      const isJustF3 = e.which === 114 && !e.altKey && !e.shiftKey && !e.ctrlKey
      if (!isJustF3) return
      ow0.menu.openMenu()
      e.preventDefault()
    })
  })

module.exports = { buildMenu }
