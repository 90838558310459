const { pdiLogo } = require('./pdi-logo')

const { qc, html } = common.cmp

const logout = function () {
  window.fetch('/logout').then(res => {
    console.log('/logout -> ', res)
    sessionStorage.removeItem('sessionid')
    window.location.reload()
  })
}

const goToLogin = async user => {
  if ($('div#screenlock')[0]) return

  if (!user) $('.workspace').html('')

  const btn = qc('input#login-button.k-button').attr({
    type: 'submit',
    'data-default-button': 'true',
    value: __('LoginSubmitButton')
  })

  const btnSso = qc('input.k-button').attr({
    type: 'button',
    value: __('LoginAAD')
  }).css(`
  display: block;
  margin: 1em auto;
  background-image: url(/images/microsoft64.png); /* 16px x 16px */
  background-color: transparent; /* make the button transparent */
  background-repeat: no-repeat; /* make the background image appear only once */
  background-position: 0px 0px; /* equivalent to 'top left' */
  cursor: pointer; /* make the cursor like hovering over an <a> element */
  height: 47px; /* make this the size of your image */
  padding-left: 64px; /* make text start to the right of the image */
  vertical-align: middle; /* align the text vertically centered */
  `)

  const form = qc('div#LoginForm.login.k-window').css({
    background: '#f3f6f8',
    boxShadow: '0.077em 0.154em 0.231em rgba(0, 0, 0, 0.25)',
    border: '0 none !important',
    borderRadius: '0.231em',
    paddingTop: '0',
    minHeight: '200px',
    minWidth: '300px'
  })
  const qTop = qc(
    'div#LoginFormContent.ow-viewwindow.win-con.ow7.ow-loading',
    __('Loading...')
  ).css({
    margin: '1rem'
  })

  form
    .kids([
      qc('div.logo', pdiLogo()).css({ padding: '1em' }),
      qTop,
      html(`<style> 
  .login {
    position: absolute;
    width: 30em;
    top: calc(50% - 8.87em);
    left: calc(50% - 15em);
    margin-top: 0 !important;
    background: #739ea8;
    box-shadow: 0.077em 0.231em 0.39em rgba(0, 0, 0, 0.5);
    border-radius: 0.231em;
    z-index: 1;
    animation: fadeIn 1.5s;
  }
  .k-widget.k-window.k-window-titleless div.login {
    position: absolute;
    width: 30em;
    left: calc(50% - 15em);
    margin-top: 0 !important;
    background: #739ea8;
    box-shadow: 0.077em 0.231em 0.39em rgba(0, 0, 0, 0.5);
    border-radius: 0.231em;
    z-index: 1;
    animation: fadeIn 1.5s;
  }
  .login .ow-ctl-wrap,
  .login input.k-textbox[type='text'],
  .login input.k-textbox[type='password'],
  .login input[type='submit'] {
    display: block;
    width: 27em;
    margin: 2em auto;
    opacity: 1;
    border: 0.077em solid #ceced2;
    border-radius: 0.231em;
  }
  .login input.k-input[type='text'] {
    margin: 1rem auto;
  }
  #login-button {
    color: #fff;
    background-color: #307ebe;
    border-color: #2e6a9c;
    box-shadow: 0.077em 0.154em 0.231em rgba(0, 0, 0, 0.25);
    width: 8.87em;
    padding-top: 0.285em;
    padding-bottom: 0.285em;
  }
  #login-button:hover,
  #login-button:active {
    background-color: #1984c8;
    border-color: #1984c8;
  }
    </style>`)
    ])
    .attr({ method: 'PUT' })

  const screenLock = qc('div#screenlock', [
    qc('div.overlay.fit').css({
      opacity: '0.75',
      background: '#222'
    }),
    form
  ]).css({ opacity: '1' })

  screenLock.renderTo(document.body)

  const domainList = !user
    ? await $ajax('/get-domains').catch(err => console.error('Error', err))
    : undefined

  qTop.removeClass('ow-loading')

  const view = { qTop }
  const { combo7, text7 } = common.ow7.wireUpView7(view)

  const userNameInput = text7({
    id: 'userId',
    placeholder: __('LoginUserId'),
    value: user ? user.UserID + (user.DBID ? '@' + user.DBID : '') : '',
    disabled: user ? true : false
  }).on('keyup', e => e.which === 13 && formSubmit(e))

  const passwordInput = text7({
    view,
    id: 'password',
    placeholder: __('Password')
  })
    .attr({ type: 'password' })
    .on('keyup', e => e.which === 13 && formSubmit(e))

  const domainInput = combo7({
    view,
    list: domainList?.data ?? [],
    value: user?.DBID,
    textField: 'DBID',
    valueField: 'DBID',
    placeholder: __('Domain'),
    disabled: domainList?.data?.[0] ? false : true
  })

  const formContent = [
    ...(!user && domainList?.showCombo
      ? [
          domainInput
            .css({ textIndent: '0.5rem' })
            .wrap()
            .css({ height: '2rem', marginBottom: '1rem' })
        ]
      : []),

    userNameInput
      .css({ display: 'block', textIndent: '0.5rem', width: '100%' })
      .wrap()
      .css({ height: '2rem', marginBottom: '1rem' }),

    passwordInput
      .css({ display: 'block', textIndent: '0.5rem', width: '100%' })
      .wrap()
      .css({ height: '2rem' }),

    btn.on('click', e => formSubmit(e))
  ]
  if (window.enableSso) {
    formContent.push(qc('hr'))
    formContent.push(btnSso)
  }
  if (user)
    formContent.push(
      qc('a#logout-link', __('Logout'))
        .attr({ href: '#' })
        .css({
          textDecoration: 'underline',
          border: 'none',
          padding: '1em',
          float: 'right',
          marginTop: '-2em'
        })
        .on('click', () => window.logout())
    )
  view.qTop.kids([formContent])

  view.afterInit()

  const setBusy = v =>
    v ? qc(document.body).addClass('wait') : qc(document.body).removeClass('wait')

  window.submitLogin = (userId, password) =>
    $ajax({
      method: 'PUT',
      url: '/login-user',
      data: { userId, password }
    }).then(response => {
      if (!response) return
      if (!response.result) throw response.details
      sessionStorage.setItem('sessionid', response.sessionid)
      window.unlockScreen()
      screenLock.el.remove()
      return response
    })

  const formSubmit = e => {
    const selectedDomain = domainInput.val()
    const [UserID, inputDBID] = userNameInput.el.val().split('@')
    const userId = user
      ? user.UserID + '@' + user.DBID
      : UserID + (selectedDomain ? '@' + selectedDomain : inputDBID ? '@' + inputDBID : '')
    const password = passwordInput.val()

    screenLock.el.remove()
    setBusy(true)

    window
      .submitLogin(userId, password)
      .then(response => {
        if (response.ChangePasswordRequired) sessionStorage.setItem('changepassword', 1)
        if (response.loginWarningMessage)
          sessionStorage.setItem('loginWarningMessage', response.loginWarningMessage)
        if (!response.isRelogin) sessionStorage.setItem('showkpi', '1')
        if (!user || user.UserID !== response?.UserID) window.location.reload()
        if (!user || user.UserID !== response?.UserID)
          setTimeout(() => window.location.reload(), 10)
        screenLock.el.remove()
        return response
      })
      .catch(err => {
        ow.popError(__('LoginErrorTitle'), err, 5000)
        goToLogin(user)
        btn.attr({ disabled: 'false' })
      })
      .finally(() => setBusy(false))

    e.preventDefault() // avoid to execute the actual submit of the form.
    return false
  }

  if (window.enableSso) {
    btnSso.el.onclick = () => {
      localStorage.setItem('ssodomain', 1) //check again when redirect from assert
      window.location = '/sso/login'
    }
  }

  userNameInput.el.focus()
}

module.exports = { goToLogin, logout }
