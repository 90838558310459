const { pdiLogo } = require('./pdi-logo')
const { theme, colors } = require('./pdi-theme')
const { topSearch } = require('./top-menu')
const { qc, html } = common.cmp

const changeLanguage = lang =>
  window.fetch('/set-lang?lang=' + lang).then(() => window.location.reload())

const languageBar = (languages = [], lang) => {
  const selectedLang =
    languages.find(x => lang === x.Value) || languages.find(x => lang === x.Value)

  const langs = () =>
    languages.map(({ Value, Flag, Text }) =>
      qc(
        'li.lang',
        qc('a', [qc('img').attr({ src: 'flags/1x1/' + Flag }), qc('label', Text)])
          .attr({ href: '#' })
          .on('click', () => changeLanguage(Value))
      )
    )

  return qc('a.header-item.opt3.lang', [
    qc('i.fa.fa-globe'), // .pdi-icon.pdi-icon-Globe'),
    qc('label', selectedLang ? selectedLang.Text : ''),
    qc('ul.sub-menu3.animate', langs())
  ])
    .css({ display: 'inline-block' })
    .attr({ href: '#' })
    .on('dblclick', () =>
      ow0.windows.showWindow(__('Edit Translations'), 'views/translation-grid.js', 800, 650, 17088)
    )
}

const storeUL = (APPUserStores = []) =>
  APPUserStores.map(({ StoreID, StoreName }) =>
    qc('li.site', qc('a', StoreName).attr({ href: '#' })).on('click', function () {
      var wins = ow0.windows.findOpenWindows()
      var anyWindowOpen = wins ? wins.length > 0 : false
      if (anyWindowOpen)
        return ow0.confirm(
          'Orbis Web',
          __('ConfirmProceedCloseWindow'),
          ok => ok && changeStore(StoreID, StoreName)
        )
      changeStore(StoreID, StoreName)
    })
  )

const sideMenuButton = () =>
  // pdi-icon-Hamburger-Menu
  qc(
    'a.sidemenu-trigger',
    // qc('div.nav-icon', [qc('span'), qc('span'), qc('span')])
    qc('i.fa.fa-bars').css({ fontSize: '1.5rem' })
  ).attr({
    href: '#'
  })

const topMenu = () => [qc('nav.topmenu.menucategory').css({ flex: 1 }), topSearch()]

const changeStore = (storeId, storeName) =>
  window
    .$ajax({
      method: 'PUT', // 'POST',
      url: '/change-store?selectedStore=' + storeId + '&selectedStoreName=' + storeName
    })
    .then(result => {
      window.updateKPIInfo(result && result.showkpi ? '1' : '')
      window.location.reload()
    })
    .catch(err => ow0.popError(__('ERROR'), JSON.stringify(err)))

const headerBar = (user, languages, locale, appVersion, currentStore, OrbisSettings, __) => {
  const accountSetting = () =>
    qc('a.opt1.header-item', [
      qc('i.fa.fa-user'), //pdi-icon-Profile
      qc('label', user.UserName),
      qc('ul.sub-menu1.animate', [
        qc(
          'li',
          qc('a', __('ChangePassword'))
            .attr({ href: '#' })
            .on('click', () => window.goToChangePassword(true))
        ),
        OrbisSettings.GENERAL?.RAPIDSTORESELECTOR === '0'
          ? qc(
              'li',
              qc('a', __('Change Store'))
                .attr({ href: '#' })
                .on('click', () =>
                  ow0.windows.showWindow(
                    __('Change Store'),
                    'views/appuser-changestore.js',
                    700,
                    500
                  )
                )
            )
          : [],
        ow0.dev
          ? qc(
              'li.lockscreen',
              qc('a.sublock', __('Lock Screen'))
                .attr({ href: '#' })
                .on('click', () => window.lockScreen())
            )
          : [],
        qc(
          'li.logout',
          qc('a.subout', __('Logout'))
            .attr({ href: '#' })
            .on('click', () => window.logout())
        )
      ])
    ])
      .attr({ href: '#' })
      .css({ display: 'inline-block' })

  return [
    styles(),
    qc('div.header-section', [
      user.SelectedMenuGroup === 0 ? sideMenuButton() : [],
      qc('nav.pdi-logo', pdiLogo()).css({
        height: 'inherit',
        margin: '0 1em'
      }),
      qc('nav.version', appVersion || '').css({
        whiteSpace: 'nowrap',
        fontSize: '0.75em',
        color: colors.CoolGray
      }),
      user.SelectedMenuGroup === 1 ? topMenu() : []
    ]).css({ flex: 1 }),
    qc('div.nav-panel.header-section', [
      OrbisSettings?.GENERAL?.SHOWLANGAUGE ? qc('nav', languageBar(languages, locale)) : [],

      qc('nav.header-item', qc('i.fa.fa-bell')) //qc('i.pdi-icon-Inbox-Incoming'))
        .attr({ href: '#' })
        .on('click', () =>
          ow0.windows.showWindow(
            __('Report download'),
            '/views/readyreport-overview.js',
            '800',
            '600'
          )
        ),
      qc(
        'nav',
        qc('label#currentStoreName.opt4.header-item', [
          qc('i.fa.fa-sitemap'), // pdi-icon-Site-Location
          qc('label', currentStore.StoreName || ''),
          OrbisSettings.GENERAL?.RAPIDSTORESELECTOR !== '0'
            ? qc('ul.sub-menu4.animate', storeUL(user.APPUserStores))
            : []
        ])
          .css({ display: 'inline-block' })
          .on('click', () => {
            if (OrbisSettings.GENERAL?.RAPIDSTORESELECTOR === '0')
              ow0.windows.showWindow(__('Change Store'), '/views/appuser-changestore.js', 700, 500)
            return false
          })
      ),

      qc('nav.header-item', accountSetting()),

      qc(
        'nav.header-item',
        qc('a', [
          // pdi-icon-Apps
          qc('i.fa.fa-th').css({
            display: 'inline-block',
            fontSize: '1.2rem'
          }),
          qc('ul.sub-menu2.animate', [
            qc('li.subhead.favourites', __('Favourites')),
            qc('li.subhead.open-windows', __('Open'))
          ])
        ]).attr({ href: '#' })
      )
    ]).css({ paddingRight: '1em' })
  ]
}

const styles = () =>
  html(`<style id="header-css">

.pdi .k-window-titlebar {
  background-color: ${theme.dark.backgroundColor};  
}

.pdi .main-content {
  top: 0;
  background: ${colors.CoolGray};
}

.pdi .store-bg {
  color: ${colors.Granite};
}

.header span.fa {
  display: inline-block;
  vertical-align: top;
}

/* New menu dropdowns */
.header {
  position: fixed;
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  color: #fff;
  background-color: ${theme.dark.backgroundColor};  
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  padding: 0;
  margin:0;
}

.header * {
  font-size: inherit;
  line-height: inherit;
}

.header nav,
.header > * {
  background: inherit ! important;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: inherit;
  line-height: inherit;
}

@media (max-width: 1366px) {
  .header:has(.topmenu) .header-item > label {
    display: none;
  }
}

@media (max-width: 800px) {
  .header:not(.topmenu) .header-item > label {
    display: none;
  }
}

.header .header-item {
  cursor: pointer;
}

.pdi-logo > svg { height: inherit; }

nav {
  vertical-align: top;
  display: inline-block;
  width: auto;
  overflow: visible;
  text-align: center;
}

.nav-panel nav {
  margin-left: 0.5rem;
}

.header i {
  line-height: inherit;
  height: inherit;
  display: inline-block;
  padding: 0 0.5rem;
  font-size: 1.3em;
}

.header ul.animate {
  padding: 1rem;
  border-radius: 4px;
  background: inherit;
  background-color: #fff;
  color: #333;
  visibility: hidden;
  line-height: 1rem;
  transform: translateY(-1em);
}

.header ul.animate a {
  color: ${colors.Granite};
}

.pdi .header ul.animate {
  background-color: ${theme.light.backgroundColor};
  color: ${theme.light.color};
}

.header :focus-within > ul.animate,
.header :focus > ul.animate,
.header :hover > ul.animate {
  opacity: 1;
  top: 2.5rem;
  z-index: -9999;
  visibility: visible;
  box-shadow: 0 0.309em 0.618em 0 rgb(0 0 0 / 20%), 0 0.4629em 12rem 0 rgb(0 0 0 / 19%);
  transition: transform 0.25s;
  transform: translateY(0%);
}

nav .sub-menu1 {
  position: absolute;
  opacity: 0;
  top: -3rem;
  z-index: -9999;
  width: 12em;
  margin: 0;
  list-style-type: none;
  text-align: left;
}

nav .sub-menu3 {
  position: absolute;
  opacity: 0;
  top: -3rem;
  z-index: -9999;
  max-width: 16.975em;
  width: inherit;
  margin: 0;
  list-style-type: none;
  text-align: left;
}

nav .sub-menu4 {
  position: absolute;
  opacity: 0;
  top: -3rem;
  z-index: -9999;
  max-width: 16.975em;
  width: inherit;
  margin: 0;
  list-style-type: none;
  text-align: left;
  overflow-y: auto;
  max-height: 550px;
}

.header ul.animate li a:hover,
.header ul.animate li:hover a {
  text-decoration: underline;
}

.header ul.sub-menu3 li {
  height: 2rem;
}

.sub-menu1 li a {
  display: block;
  line-height: 1.5em;
  color: ${colors.Granite};
  padding: 0.4em 0;
  z-index: 0;
  width: 100%;
  word-wrap: break-word;
  padding-left: 1px;
  padding-right: 1px;
}
.sub-menu3 li a,
.sub-menu4 li a {
  display: block;
  color: ${colors.Granite};
  padding: 0.6em 0;
  z-index: 0;
  min-width: 12em;
  width: 100%;
  word-wrap: break-word;
}

.sub-menu1 li a,
.sub-menu3 li a,
.sub-menu4 li a {
  cursor: pointer;
}
.lang img {
  display: inline-block;
  width: 1.5em;
  margin: 0 0.8em 0 0;
  border-radius: 1em;
  box-shadow: 0.0771em 0.0771em 0.2314em 0px rgba(0, 0, 0, 0.35);
}
li.lang a {
  display: flex;
  align-items: center;
}
li.lang a label {
  cursor: pointer;
}

.sub-menu2 {
  position: absolute;
  opacity: 0;
  top: -3rem;
  right: 0;
  z-index: -9999;
  width: 36em;
  max-width: 40em;
  padding: 0.25em 0.5em;
  list-style-type: none;
  text-align: left;
  background: rgba(255, 255, 255, 0);
  color: rgba(76, 76, 76, 0);
}
@media screen and (max-width: 660px) {
  .sub-menu2 {
    position: absolute;
    opacity: 0;
    top: -3rem;
    right: 0;
    z-index: -9999;
    padding: 0.25em 0.5em;
    list-style-type: none;
    text-align: left;
    background: rgba(255, 255, 255, 0);
    color: rgba(76, 76, 76, 0);
    width: 15em;
  }
  :hover > .sub-menu2 {
    opacity: 1;
    top: 2.5rem;
    z-index: -9999;
    background: rgba(255, 255, 255, 0.95);
    color: rgba(76, 76, 76, 1);
    width: 15em;
  }
}

.sub-menu2 li {
  display: inline-block;
  height: 1.5em;
  text-align: left;
  color: ${colors.Granite};
  margin: 0.25em 0.25em 0.25em 0;
  white-space: nowrap;
  z-index: 0;
}
.sub-menu2 li a {
  cursor: pointer;
}

.subhead {
  display: inline-block;
  height: 1.5em;
  width: 100%;
  margin: 0;
  text-indent: 0.5em;
  font-weight: 600;
  color: #4c4c4c;
}

.subfav {
  background: ${colors.Sienna};
  color: #fff !important;
  padding: 0.15em 0.5em;
  border-radius: 0.309em;
}

.subope {
  background: ${colors.ProductAction};
  color: #fff !important;
  padding: 0.15em 0.5em;
  border-radius: 0.309em;
}
.pdi .subope { background: ${colors.Ocean}; }

.suboth {
  background: #2c3e50;
  color: #fff !important;
  padding: 0.15em 0.5em;
  border-radius: 0.309em;
}

.nav-icon {
  position: relative;
  width: 2.7em;
  height: 2.32em;
  margin: 0.849em 0.39em 0;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.nav-icon span {
  position: absolute;
  display: block;
  width: 70%;
  height: 0.25em;
  left: 10%;
  background: #fff;
  border-radius: 0.154em;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.header form {
  margin: 0.39em 0.772em;
}
.header form label {
  margin: 0 0.772em;
}
.header form input.submit {
  line-height: 1em;
  height: 2.24em;
  margin-top: -0.39em;
  margin-left: 0.39em;
}
.header-section {
  display: flex;
  align-items: center;
}
</style>
`)

module.exports = { headerBar }
