// homepage builders
if (!window.fetch) require('whatwg-fetch') // for jsdom

const { common } = require('common')
const { qc } = common.cmp

const { goToTfa } = require('./homepage-tfa')
const { goToDomain } = require('./homepage-ssodomain')
const { goToChangePassword } = require('./homepage-changepassword')
window.goToChangePassword = goToChangePassword

const { goToLogin, logout } = require('./homepage-login')

const { startPing, io } = require('./homepage-ping')

window.io = io
window.usePUT = true // tells the ow libs, fileupload etc

const createDOMPurify = require('dompurify')
window.DOMPurify = createDOMPurify(window)

const { kendo, $, ow0, ow4 } = window

const { buildMenu } = require('./build-menu')
const { initReadyReport } = require('./homepage-readyreport')
const { headerBar } = require('./homepage-header-bar')
const { globalMessaging } = require('./global-messaging')

const {
  startIdleChecks,
  unlockScreen,
  lockScreen,
  isScreenLocked
} = require('./homepage-screenlock')

window.lockScreen = lockScreen
window.unlockScreen = unlockScreen

const fetchAndRun = url => {
  console.log('fetch and run script:', url)
  return window
    .fetch(url)
    .then(r => r.text())
    .then(js => eval(js))
}

const storeInfoBG = ({ StoreName, StoreType }, __ = s => s) => `
  <div>${StoreName}</div>
  ${__(['Back Office', 'Warehouse', 'Central', 'In Transit'][StoreType])}
`

const setHomePageConfig = (title = 'PDI', lang) => {
  document.title = 'PDI' ?? title
  document.documentElement.setAttribute('lang', lang)
}

const loadHomePageData = () => window.fetch('/homepage-locals')

const waitScreenLock = async url => {
  if (isScreenLocked() && [0, 1].includes(url.indexOf('data/'))) {
    console.log('idletimeout. ajax call waiting: ' + url)
    while (isScreenLocked()) await common.wait(1000)
    console.log('idletimeout ended. Calling ajax: ' + url)
  }
}

const overrideFetch = () => {
  $.ajaxSetup({
    beforeSend(jqXHR, settings) {
      const sessionid = sessionStorage.getItem('sessionid')
      if (sessionid) jqXHR.setRequestHeader('sessionid', sessionid)

      jqXHR.setRequestHeader('credentials', 'same-origin')

      if (settings.url.indexOf('data/') === 0 || settings.url.indexOf('view/') === 0)
        settings.url = '/' + settings.url

      if (settings.type.toUpperCase() === 'POST' && settings.url.indexOf('/data') === 0) {
        // console.warn('Using PUT not POST for ', settings.url)
        settings.type = 'PUT'
      }
    }
  })

  const noDataPOST = opts => {
    // first char should be /, if data/ or view/
    if (opts.url.indexOf('data/') === 0 || opts.url.indexOf('view/') === 0)
      opts.url = '/' + opts.url

    // if (opts.url.substring(0, 2) === '//')

    if (opts.method === 'post' && opts.url.indexOf('/data') === 0) {
      console.warn('Using PUT not POST for ', opts.url)
      opts.method = 'put'
    }
  }

  const isExternalURL = url => new URL(url, location).origin !== location.origin

  // some of these things we never way to send to external urls
  const isSameOrigin = url => !isExternalURL(url)

  // url.substring(0, 2) !== '//' && url.substring(0, 5) !== 'http:'

  const setHeaderContentType = opts => {
    if (!isSameOrigin(opts.url)) return

    opts.headers = opts.headers || {}
    opts.headers['content-type'] = opts.headers['content-type'] || 'application/json'
    opts.headers['X-Requested-With'] = 'XMLHttpRequest'
    opts.headers['credentials'] = opts.headers['credentials'] || 'same-origin'
  }

  const setHeaderSid = opts => {
    if (!isSameOrigin(opts.url)) return

    opts.headers = opts.headers || {}
    const sessionid = sessionStorage.getItem('sessionid')
    if (sessionid) opts.headers.sessionid = sessionid
  }

  const pre = (fn, fn2) =>
    function (...args) {
      return fn2.call(this, fn, ...args)
    }

  $.ajax = pre($.ajax, async function (orig, ...args) {
    await waitScreenLock(typeof args[0] === 'string' ? args[0] : args[0].url)
    if (args[0].error) {
      const origErrHandler = args[0].error
      args[0].error = function (jqXHR) {
        if (jqXHR.status === 504) jqXHR.responseText = jqXHR.statusText
        origErrHandler.call(this, jqXHR)
      }
    }
    return orig(...args)
  })

  window.pre$ajax = async (...args) =>
    waitScreenLock(typeof args[0] === 'string' ? args[0] : args[0].url)

  window.fetch = pre(window.fetch, async (orig, ...args) => {
    if (args[0] === '/csrfToken')
      return { json: () => ({ _csrf: 'HLKJHSD2aa4sYBI&23GBFuhd9890uHPIUSP7' }) }

    if (args.length === 1) {
      if (typeof args[0] === 'string') args.push({})
      else {
        noDataPOST(args[0])
        setHeaderSid(args[0])
        setHeaderContentType(args[0])
      }
    }

    if (args.length === 2) {
      args[1].url = args[0] // for noDataPOST to know the
      noDataPOST(args[1])
      setHeaderSid(args[1])
      setHeaderContentType(args[1])
    }

    return orig(...args)
  })
}

window.logout = logout

window.updateKPIInfo = v => sessionStorage.setItem('showkpi', v)
window.getKPIInfo = () => sessionStorage.getItem('showkpi')

const checkSubscribedKPI = command => {
  sessionStorage.removeItem('showkpi')
  if (!command) return
  ow0.windows.openView({
    name: __('KPI'),
    url: 'views/kpi-view.js',
    funcid: 17003,
    command
  })
}

const initMenu = skipLeavePageCheck => {
  // var $menu = $('.menu').kendoMenu({ orientation: 'horizontal' })
  // var kMenu = $menu.data('kendoMenu')

  $('*').on('click', function (e) {
    var bClose = false
    var hoverTopMenu = $(this).closest('.menu-hover')
    var elUL = $(this).closest('.menu-max-cols')

    // if clicked inside the content display, then stop bubble
    if (hoverTopMenu.length > 0) {
      e.stopImmediatePropagation()
      return false
    }

    // if submenu is clicked
    if ($(this).hasClass('link-topmenu')) bClose = true
    // if clicked on other menuitem
    else if (hoverTopMenu.length === 0) {
      var topMenuUL = $('.menucategory').find('ul#nav')
      if (topMenuUL) hoverTopMenu = topMenuUL.find('.menu-hover')

      bClose = true
    }
    // if click on self
    else if (elUL.length === 0) bClose = true

    if (bClose && hoverTopMenu) setTimeout(() => hoverTopMenu.removeClass('menu-hover'), 500)
  })

  $('body').trigger('click') // register

  window.onbeforeunload = e => {
    if (ow0.windows.findOpenWindows().length > 0 && skipLeavePageCheck !== 'true')
      if ($(ow0.windows.findOpenWindows()[0]).attr('id') !== 'Login') {
        var msg = 'This action will close all the open window, are you sure you want to proceed?'
        e.returnValue = msg
        return msg
      }
  }

  var mnuMain
  mnuMain =
    mnuMain ||
    ow4.controls.contextMenu({
      menuItems: [
        {
          text: 'Close All Windows (Alt + X)',
          encoded: false,
          command: 'close-all-opened',
          cssClass: 'mnu-close-all-opened',
          click() {
            ow0.confirm(
              'Confirm',
              __('ConfirmCloseAllOpened'),
              r => r && ow0.windows.closeAllOpenWindows()
            )
          }
        }
      ],
      target: $('body')[0],
      targetFilter: 'div' //further filter when ow-menu-open
    })
  $(mnuMain).on('ow-menu-open', (e, originalEvent) => {
    ow0.windows.findOpenWindows().length
      ? $(e.target).find('li.mnu-close-all-opened').show()
      : $(e.target).find('li.mnu-close-all-opened').hide()
    if (!$(originalEvent.target).hasClass('main-content')) {
      $(e.target).find('li.mnu-close-all-opened').hide()
    }
  })
}

window.homePageInit = async () => {
  // return goToDomain()
  var sessionid = sessionStorage.getItem('sessionid')
  if (!sessionid) return goToLogin()
  if (localStorage.getItem('ssodomain') === '1') return goToDomain()

  overrideFetch()

  let response = await loadHomePageData(sessionid).catch(err => {
    console.error('Error loading homepage data', err)
    return {}
  })

  if (!response.ok) {
    if (response.ok === false) sessionStorage.removeItem('sessionid')
    return goToLogin()
  }

  const data = await response.json()

  ow0.locale = data.locale || 'en-US'
  ow0.lang = data.lang // or cookies.lang?
  await fetchAndRun('/translation/' + ow0.lang).catch(() => {})
  await fetchAndRun('/dependencies/cultures/' + ow0.locale + '.min.js').catch(() => {})
  await fetchAndRun('/dependencies/cultures/' + ow0.lang + '.min.js').catch(() => {})

  if (data.result === false) {
    if (data.goto === '/tfa') goToTfa(data.dataUrl)
    return
  }
  if (sessionStorage.getItem('changepassword') === '1') return goToChangePassword()

  kendo.culture(ow0.locale)

  common.setCulture(kendo.cultures.current, kendo.cultures[ow0.lang], data.OrbisSettings, kendo)

  $.ajaxSetup({ cache: false })

  window._selectedMenuGroup = data.user?.SelectedMenuGroup
  window.cacheUrl = data.cacheUrl

  ow0.cacheUrl = data.cacheUrl
  ow0.hosis = ow0.hosis || {}

  ow0.setCurrentStore = currentStore => (ow0.currentStore = currentStore)

  ow0.setCurrentStore(data.currentStore)

  ow0.UserID = data.user.UserID
  ow0.UserDealerID = data.user.DealerID
  ow0.defaultDomain = data.defaultDomain || 'ORBIS'
  ow0.DBID = data.dbId
  ow0.userHasFullRights = data.userHasFullRights
  ow0.orbisVersion = data.appVersion
  ow0.dev = data.isDev
  ow0.env = data.env
  ow0.enableSso = data.enableSso

  if (ow0.dev)
    setTimeout(
      () =>
        qc(
          'nav.dev-menu',
          qc('a.menu-toplink', 'Dev').on('click', () => window.launchView('views/dev.js'))
        ).renderTo(document.querySelector('div.header')),
      500
    )

  ow0.reportNewWindow = data.OrbisSettings.GENERAL.REPORTNEWWINDOW
  ow0.canOpenReport = data.reportAccessibility?.CanRead || false

  setHomePageConfig(data.title, ow0.lang)
  document.querySelector('div.store-bg').innerHTML = storeInfoBG(data.currentStore)
  qc(document.querySelector('div.header')).kids(
    headerBar(
      data.user,
      data.languages,
      data.lang,
      data.appVersion,
      data.currentStore,
      data.OrbisSettings,
      __
    )
  )

  $('#menu-bar').removeClass('hidden')

  initReadyReport()
  ow0.windows.windowListMenu()
  if (ow0.storage) ow0.storage.saveInterval = parseInt(data.appData.SAVEDRAFTINTV)

  kendo.ui.Menu.fn._mouseleave = function () {}

  const loginWarningMessage = sessionStorage.getItem('loginWarningMessage')
  if (loginWarningMessage) {
    ow0.popWarning(__('Warning'), loginWarningMessage, 5000)
    sessionStorage.removeItem('loginWarningMessage')
  }
  const justChangedPassword = sessionStorage.getItem('justChangedPassword')
  if (justChangedPassword) {
    ow0.popSuccess(__('PasswordChanged'))
    sessionStorage.removeItem('justChangedPassword')
  }
  initMenu(data.OrbisSettings.GENERAL?.SKIPLEAVEPAGECHECK)

  // moved from homepage-script
  if (ow0.dev) globalMessaging(window.io, window.ow0)
  buildMenu()

  // Check for inactivity & perform logout
  let idleMin = parseInt(data.appData.IDLETIMEOUT ?? 500000)

  // idleMin = 15000 // test
  if (!data.isSsoLogon && idleMin !== 0) startIdleChecks(idleMin)

  //register user polling process
  io.socket.on('longprocess', data =>
    data.err
      ? ow0.popError(
          'ERROR',
          typeof data.err === 'string' ? data.err : JSON.stringify(data.err),
          5000
        )
      : ow0.popInfo('Information', data.msg, 5000)
  )

  if (window.getKPIInfo()) checkSubscribedKPI(data.user.KPICommand)

  if (ow0.dev) {
    console.log(
      'dev mode, adding window.launchView and window.qc for convienience and loading homepageExta'
    )
    window.qc = qc
    window.launchView = x => setTimeout(() => ow0.windows.openView(x), 100)

    if (data.homepageExtra)
      fetchAndRun(
        data.homepageExtra === true ? '/js/homepage-' + ow0.env + '.js' : data.homepageExtra
      )
  }

  startPing()
}

document.addEventListener('DOMContentLoaded', window.homePageInit)
