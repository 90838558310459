const { theme } = require('./pdi-theme')

const { $ajax, nojquery, controls } = common
const { input4 } = controls
const { $find } = nojquery
const { qc, html } = common.cmp

const allowMultiMenuFunc = [9002, 17006, 17002, 17003]

const reportFuncId = 9002

const isReport = m => m.FunctionID === reportFuncId //  (m.functype === 3 && m.command?.length) || m.FunctionID === reportFuncId

const isMenuPanel = m => m.FunctionID === 10001 || m.FunctionID === 0

const getTranslatedMenuName = mi => {
  let translation = mi.TranslationMapping?.LangText || mi.TranslationMapping2?.LangText || null
  if (translation) return translation
  const txKey = 'MENU:' + mi.Name
  const tx = __(txKey, true)
  return tx !== txKey ? tx : __(mi.Name, true)
}

const clickMenu = data => {
  if (isMenuPanel(data)) return

  if (isReport(data)) return ow.openReport(...(data.Command?.split(';') ?? []))

  showWindow(
    getTranslatedMenuName(data),
    data.menuSetting?.viewFileName,
    data.menuSetting?.windowWidth,
    data.menuSetting?.windowHeight,
    data.FunctionID,
    ...(data.Command?.trim().length ? [data.Command] : [])
  )

  return false
}
ow.menu.clickMenu = clickMenu

const favIcon = menuItem => {
  const icon = qc('i.fa.fa-1x')
    .attr({ id: 'fav_' + menuItem.LineNumber, 'aria-hidden': 'true' })
    .css({
      float: 'right',
      margin: '0.62em 0.77px 0 0',
      verticalAlign: 'middle',
      lineHeight: '1em'
    })
    .on('click', e => {
      modifiedFavourite(menuItem).then(() => {
        menuItem.isFavourite = !menuItem.isFavourite
        icon.renderAsync()

        ow.menu.shortcuts = ow.menu.shortcuts.filter(
          short => !(short.FunctionID == menuItem.FunctionID && short.Name == menuItem.Name)
        )

        if (menuItem.isFavourite)
          ow.menu.shortcuts.push({ ...menuItem, display: menuItem.display, menuItem })

        window.rebuildShortcuts()
      })

      e.stopPropagation()
      return false
    })
    .bindState(
      () => menuItem.isFavourite,
      fav => icon.addClass(fav ? 'fa-star' : 'fa-star-o').removeClass(fav ? 'fa-star-o' : 'fa-star')
    )

  return icon
}

const genIcon = mi =>
  isMenuPanel(mi) ? [qc('i.fa.fa-angle-left.fa-pull-left.fa-2x')] : [favIcon(mi)]

const backButton = () =>
  qc('a.mp-back.k-button', [
    __('back'),
    qc('i.fa.fa-angle-right.fa-2x').css({ marginRight: '20px', float: 'right' })
  ]).attr({ href: '#', draggable: 'false' })

const buildSideMenu = (mpMenu, m, parentPanel, level = 1) => {
  m.display = getTranslatedMenuName(m)

  let open = false

  // returns a link MenuItem OR a open Panel Item
  const me = qc('li.side-menu-item')

  if (!isMenuPanel(m))
    return me
      .kids(
        qc('a.k-button', [
          qc('span', [
            // qc('i.fa').addClass('fa-' + m.WebMenuIcon || 'home'),
            m.display
          ]),
          genIcon(m)
        ]).attr({ href: '#' })
      )
      .on('click', e => {
        clickMenu(m)
        e.preventDefault()
        return false
      })

  const menuHeader = qc('a.k-button', [qc('span', m.display), genIcon(m)])
    .attr({ href: '#' })
    .on('click', e => {
      open = true
      me.addClass('mp-level-open')
      parentPanel.childOpen = true
      mpMenu.left = 400 + 30 * level
      mpMenu.renderAsync()
      e.preventDefault()
      return false
    })

  const childMenus = m.menuChild
    ? m.menuChild.map(kid => buildSideMenu(mpMenu, kid, me, level + 1))
    : []

  const mpLevel = isMenuPanel(m)
    ? qc('div.mp-level.k-header', [
        qc('div.leftbit', [
          qc('h2', [getTranslatedMenuName(m), qc('i.fa.fa-cogs.fa-2x')]),
          backButton().on('click', e => {
            open = false
            me.removeClass('mp-level-open')
            parentPanel.childOpen = false
            mpMenu.left = 400 + levelSpacing * (level - 1)
            mpMenu.renderAsync()
            e.preventDefault()
            return false
          }),
          qc('ul', childMenus).css({
            height: 'calc(100% - 124px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          })
        ]).css({
          display: 'inline-block',
          width: '300px',
          height: '100%'
        }),

        qc('div.transparent-cover.fit')
          .css({ opacity: '0' })
          .bindState(function () {
            this.css({ display: me.childOpen ? undefined : 'none' })
          })
          .on('click', () => {
            me.childOpen = false
            childMenus.forEach(x => x.close?.())
            me.removeClass('mp-child-open')
            mpMenu.renderAsync()
            mpMenu.left = 400 + levelSpacing * level
            mpMenu.renderAsync()
          })
      ])
        .css({
          whiteSpace: 'nowrap',
          height: '100%',
          boxSizing: 'content-box',
          textAlign: 'left'
        })
        .bindState(
          () => open,
          isOpen => {
            isOpen ? mpLevel.addClass('mp-level-open') : mpLevel.removeClass('mp-level-open')
            mpLevel.css({ left: isOpen ? -levelSpacing + 'px' : '-400px' })
          }
        )
        .bindState(() =>
          me.childOpen ? me.addClass('mp-child-open') : me.removeClass('mp-child-open')
        )
    : []

  return me.kids([menuHeader, mpLevel]).props({
    close() {
      open = false
      me.removeClass('mp-level-open')
      me.childOpen = false
      childMenus.forEach(m => m.close?.())
      mpMenu.renderAsync()
    }
  })
}

const levelSpacing = 34

const sideMenu = data => {
  let open = false

  const me = qc('nav.mp-menu.mp-overlap')

  let ul = qc('ul.menu-content')
  let searchInput

  const childMenus = data.map(m => buildSideMenu(me, m, ul))
  ul.kids(childMenus)

  me.kids(
    qc('div.mp-level', [
      qc('h2', [
        (searchInput = input4({ type: 'text', placeholder: __('Search') })
          .css({
            border: '1px solid #dedee0', // + borderColor,
            outline: 'none',
            width: '78%',
            // fontSize: '1em',
            padding: '0.077em',
            borderRadius: '0.31em'
          })
          .on('input', () => searchDiv(searchInput.val()))
          .on('keydown', e => {
            // Escape
            if (e.which === 27) {
              e.target.value = ''
              searchDiv()
              trigger.click()
              return
            }

            // Enter - clicks the first item
            if (e.which === 13) {
              $find('ul.ulSearchMenu a')[0]?.click()
              searchDiv()
              e.preventDefault()
            }
          })).wrap(),
        qc('i.fa.fa-search.fa-2x')
          .css({ float: 'right' })
          .bindState(
            () => (ul?.childOpen ? '1' : '0'),
            (opacity, i) => i.css({ opacity })
          )
      ]).css({ padding: '0' }),
      ul
    ])
  )

  me.props({
    close() {
      open = false
      me.removeClass('mp-level-open')
      searchDiv()
      searchInput.val('')
      me.left = 400
      childMenus.forEach(m => m.close())
    }
  })

  const searchDiv = param => {
    $find('.ulSearchMenu')[0]?.parentElement?.remove()
    if (!param) {
      searchInput.val('')
      return
    }
    const funcIds = []

    param = param.toLowerCase()

    return qc(
      'div',
      qc(
        'ul.ulSearchMenu',
        ow.menu.menuItems
          .filter(m => {
            m.display = getTranslatedMenuName(m)

            if (isMenuPanel(m)) return false

            if (m.display.toLowerCase().indexOf(param) < 0) return false
            if (!allowMultiMenuFunc.includes(m.FunctionID)) {
              if (funcIds.includes(m.FunctionID)) return false
              funcIds.push(m.FunctionID)
            }
            return true
          })
          .map(m =>
            qc('li.side-menu-item', [
              qc('a', [
                qc('span', [
                  m.display,
                  favIcon(m).css({
                    marginRight: '1em',
                    lineHeight: '1em',
                    marginTop: '0.3em',
                    marginBottom: '0'
                  })
                ])
              ]).attr({ href: '#' })
            ]).on('click', () => clickMenu(m))
          )
      )
    )
      .css({
        position: 'absolute',
        zIndex: '999999999',
        top: '6.2em',
        left: '0'
      })
      .renderTo(document.body)
  }

  const trigger = $find('.sidemenu-trigger')[0]

  ow.menu.openMenu = () => trigger.click()

  // open (or close) the menu
  qc(trigger).on('click', e => {
    e.stopPropagation()
    e.preventDefault()
    if (open) me.close()
    else {
      open = true
      me.addClass('mp-level-open')
      me.renderAsync()
    }
    searchInput.el.focus()
  })

  // the menu should close if clicking somewhere on the body (excluding clicks on the menu)
  qc(document.body).on('click', e => {
    if (open && !e.target.closest('.mp-menu')) me.close()
  })

  me.left = 400

  me.wrapper = qc(
    'div.mp-pusher',
    me.bindState(
      () => (open ? me.left : 0) + 'px',
      left => {
        me.wrapper.css({ left })

        const lis = $find('nav li')

        setTimeout(
          () =>
            lis.forEach(el => {
              const li = qc(el)
              li.css({ fontSize: li._weirdHack ? undefined : '99.9%' })
              li._weirdHack ? delete li._weirdHack : (li._weirdHack = true)
            }),
          500
        )
      }
    )
  )
    .css({ position: 'absolute' })
    .css(theme.light)

  return qc('div.side-menu-wrapper', [sideMenuStyles(), me.wrapper])
}

//     this.tabIndexes.map(ti => qc(ti).attr({ tabindex: val ? undefined : '-1' }))

const modifiedFavourite = mi =>
  $ajax({
    method: 'POST',
    url: '/data/menu/method/' + (mi.isFavourite ? 'deleteUserFavorites' : 'addUserFavorites'),
    data: {
      FunctionID: mi.FunctionID,
      Command: mi.Command, // LineNumber!!
      MenuName: mi.Name
    }
  })
    .then(() => {
      ow.popSuccess(
        __('Success'),
        'Menu Item: [' +
          getTranslatedMenuName(mi) +
          '] ' +
          (mi.isFavourite ? 'removed from favourites' : 'added to favourites'),
        3000
      )
    })
    .catch(err => ow.popError(__('ERROR'), JSON.stringify(err)))

const showWindow = (...args) => ow.windows.showWindow(...args)

const sideMenuStyles = () =>
  qc(
    'style',
    html(`
ul.ulSearchMenu {
margin: 0;
list-style-type: none;
background: black;
padding-left: 0;
padding-right: 0;
opacity: 0.8;
width: 300px;
}
ul.ulSearchMenu li {
padding-left: 0.39em;
padding-bottom: 0.39em;
padding-top: 0.39em;
font-size: 1.2em;
border: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
background: inherit;
}
ul.ulSearchMenu li:hover {
padding-left: 0;
border-left-width: 0.39em;
border-left-color: #307ebe;
border-left-style: solid;
}

ul.ulSearchMenu li a {
width: 100%;
color: #efefef;
text-decoration: none;
display: inline-block;
padding-left: 0.772em;
font-size: 0.8em;
}
ul.ulSearchMenu li a > i,
ul.ulSearchMenu li a > i {
float: right;
margin: 0.231em 0.772em 0 0;
vertical-align: middle;
}
ul.ulSearchMenu li a > i,
x:-moz-any-link,
x:default {
/*for FF only*/
margin-top: -1.23em;
}    
ul.ulSearchMenu li a:hover {
color: #ffffff;
}

.mp-menu .ow-button:focus,
.mp-menu .ow-button.k-state-focused,
.mp-menu .k-button:focus,
.mp-menu .k-button.k-state-focused {
  border: 0;
}
.mp-pusher {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
}
.mp-menu {
  position: absolute;
  left: 0;
  z-index: 999999;
  width: 23.148em;
  height: 100%;
  left: -400px;
  background: inherit;
  color: inherit
}

/* Use below with sub header instead... */
.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -400px;
}

.mp-level.mp-level-overlay {
  cursor: pointer;
}
.mp-level.mp-level-overlay.mp-level::before {
  height: 100%;
  background: transparent;
  opacity: 1;
}
.mp-pusher,
.mp-level {
  -webkit-transition: left 0.5s;
  -moz-transition: left 0.5s;
  transition: left 0.5s;
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
  z-index: 1;
  left: 0;
  box-shadow: 0 0.39em 0.231em 0.077em rgba(0, 0, 0, 0.3) !important;
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  box-shadow: none;
  left: 0;
}

.mp-level.mp-level-open {  left: 0; }

.mp-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mp-menu h2 {
  margin: 0;
  padding-left: 0.772em;
  font-size: 1rem;
  height: 3rem;
  line-height: 3rem;
}

.mp-menu.mp-overlap h2 > i {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -0.7em;
  font-size: 1.3em;
  line-height: 2;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.1s 0.5s;
  -moz-transition: opacity 0.3s, -moz-transform 0.1s 0.5s;
  transition: opacity 0.3s, transform 0.1s 0.5s;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}
.mp-menu.mp-cover h2 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.077em;
  font-size: 1em;
}
.mp-overlap .mp-level.mp-level-overlay > h2 > i {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.mp-menu ul li > a {
  display: block;
  padding: 0.5em 1em;
  outline: none;
  line-height: 2em;
  text-align: left;
  border: 0;
  border-radius: 0;
}
.mp-menu ul li::before {
  display: block;
  line-height: 1em;
  font-size: 1em;
  margin: 1.16em 0.772em 0 0.772em;
}

div.mp-level > ul > li > i {
  margin: 0.39em 0.772em;
}
div.mp-level-open > a > i {
  float: right;
  margin: 0 0.772em;
}
div.mp-level-open > ul > li > a > i {
  margin: 0 0.772em 0 0;
}

.mp-back.k-button {
  height: 3.24em;
  font-size: 0.9em;
  text-align: left;
  margin-top: -0.077em;
  border-radius: 0;
  padding: 0.75em 0em 0.75em 2em;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;
}
.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
  background: transparent;
  box-shadow: none;
  color: transparent;
}

.mp-level-open > div > ul::-webkit-scrollbar {
  width: 0.3em;
}
.mp-level-open > div > ul::-webkit-scrollbar-thumb {
  background: #555;
}

/* Experiment for iss22... */
.mp-back.k-button {
  width: 100%;
  border-right: none;
}
`)
  )

module.exports = { sideMenu, buildSideMenu, getTranslatedMenuName }
